import React from 'react';
import ContactPage from "./Common/ContactPage";

const NIY = () => {
    return (
        <>
        <h1 style={{marginTop: 300}}>
            NIY
        </h1>
            <div style={{height: 0,background:'white' }}/>
            <ContactPage/></>
    );
};

export default NIY;