export const items = [
    {
        icon: 'aircondition.png',
        text: 'בוקר טוב.\nהשעון מצלצל ואתם מתעוררים לאטכם וכדי שיהיה לכם יותר נעים להתעורר, התריסים נפתחים באופן אוטומטי ' +
            'לחצי גובה ומכניסים לחדר אור טבעי של הבוקר החדש.\n' +
            'במקלחת הדוד כבר חימם את המים והחיישן מזהה אתכם ומדליק את האור כדי שתוכלו להתרענן בנוחות לפני שהיום מתחיל.',
        hour: 6,
        min: 45
    },
    {
        icon: 'aircondition.png',
        text: 'התריסים בחדר הילדים נפתחים ומכניסים פנימה את היום החדש. הילדים קמים ומתחילים להתארגן ליציאה ללימודים.\n' +
            'בסלון, מערכת הסאונד מופעלת אוטומטית עם מוסיקה נעימה. אחרי היציאה מהבית המערכת מזהה שכולם יצאו מהבית ומפעילה באופן אוטומטי' +
            ' תסריט ״נעילה״ ומיד כל התריסים נסגרים, כל האורות כבים ואתם יוצאים לדרך.',
        hour: 7,
        min: 15
    },
    {
        icon: 'aircondition.png',
        text: 'הילדים חוזרים מבית הספר - הבית מחכה לקראת שובם: המזגן נדלק רבע שעה קודם, התריסים נפתחים באופן אוטומטי כדי להכניס אור יום, ' +
            'אם חשוך תאורת הכניסה נדלקת ברגע שהם נכנסים הביתה. אמא מקבלת הודעה בטלפון שהילדים חזרו הביתה.',
        hour: 13,
        min: 0
    },
    {
        icon: 'aircondition.png',
        text: 'בדרך הביתה מהאפליקצייה אתם נותנים הוראה לדוד החשמל להתחיל לפעול כדי שכבר יחכו לכם מים חמים למקלחת.\n' +
            'בבית הילדים משחקים, נכנסים ויוצאים מהחדרים שלהם לסלון ומשם למטבח ושוב לסלון... המערכת החכמה דואגת לכבות אחריהם באופן אוטומטי' +
            ' את האור והמזגן בחדרים הריקים ועוזרת לכם לחסוך בחשמל.',
        hour: 17,
        min: 0
    },
    {
        icon: 'aircondition.png',
        text: 'נכנסים לשעות הערב. הבית החכם שלכם יודע כבר לזהות שמחשיך ומפעיל את התאורה אוטומטית - רק בחדר בו אתם נמצאים.\n' +
            'המזגן יפסיק לפעול לאחר שזיהה שהחדר כבר התקרר והגיע לטמפרטורה שהגדרתם לו לכיבוי אוטומטי.',
        hour: 19,
        min: 0
    },
    {
        icon: 'aircondition.png',
        text: 'אתם מתרווחים על הספה בסלון, ובלחיצת כפתור באפליקצייה, מפעילים תסריט ״קולנוע״.\nהאורות מתעממים, מערכת הקולנוע הביתית שלכם נדלקת ועל המסך מתחיל ' +
            'הסרט שתכננתם לראות, אתם רק תדאגו לפופקורן.',
        hour: 21,
        min: 0
    },
    {
        icon: 'aircondition.png',
        text: 'הגיע הזמן ללכת לישון. לחיצה קצרה על תסריט ״לילה טוב״ תכבה את האורות הדלוקים בבית ואת המזגן בסלון, תסגור את התריסים החשמליים ותדליק את מנורת הלילה בחדר השינה - הכל בלחיצת ' +
            'כפתור אחת.',
        hour: 23,
        min: 0
    },{
        icon: 'aircondition.png',
        text: 'נגמר היום. מחר יום חדש מתחיל',
        hour: 24,
        min: 0
    }
];

export const officePreText = 'במשרד החכם בו מותקנת מערכת גרין פוינט' +
    ' לא יהיה עוד ביזבוז של חשמל.\n' +
    ' חיישני הסביבה ינטרו את עוצמת האור, הטמפרטורה והנוכחות וידאגו לכבות את המזגן ' +
    'והאור כשהחדר במשרד לא בשימוש. כמובן שבימי העבודה חלק מהמזגנים כבר ידלקו זמן מה לפני הגעת עובדי המשרד' +
    ', ובערב המשרד יפסיק להפעיל באופן אוטומטי את המזגנים כך שכאשר שירותי הניקיון יכנסו לחדר, המזגן לא ידלק.\n' +
    'מערכת השליטה והבקרה תאפשר לקבל מידע רלוונטי אודות צריכת האנרגיה, נוכחות ואפילו טמפרטורה חריגה לטלפון הנייד שלך.';