export const about1 = 'ברוכים הבאים לבית החכם של גרינפוינט.\n ' ;
export const about0 =
    'עולם של נוחות, חדשנות וחיסכון בחשמל.';
export const aboutNew2='חברת גרין פוינט, מפתחת ומייצרת בישראל את הבית החכם מסדרת ' +
    'Igh - Intelligent green home.'+
    '\n הבית החכם תוכנן לפעול בשבילכם באופן אוטומטי ולהפוך את המגורים בבית' +
    ' לחוויה מתקדמת של הנאה, נוחות, איכות חיים וחיסכון משמעותי בהוצאות החשמל.\n' +
    'המערכת מתאימה לבית חדר או קיים כאחד ומאפשרת הפיכת בית לחכם וחסכוני על ידי ' +
    ' החלפה פשוטה של המתגים הקיימים במתגי Igh חכמים.\n'
    ;
export const aboutThird=
    'לכולם.\n '+
    ' \n מדירות קטנות לגדולות, וילות יוקרה חדשות או קיימות, בניינים, משרדים שכורים או קנויים, בתי חולים, חדרי כושר, מלונות, חדרי אירוח, בתי ספר, מפעלים ועוד.\n'+
    ' לא משנה איפה אתם נמצאים, תנו למערכת לעשות את כל העבודה הקשה בשבילכם כך שבקרוב תשכחו איך לוחצים על מתגים.\n '

;
export const about2 = 'חברת גרין פוינט, מפתחת ומייצרת בישראל את הבית החכם מסדרת Igh - Intelligent green home ' +
    '\n הבית החכם תוכנן לפעול בשבילכם באופן אוטומטי ולהפוך את המגורים בבית' +
    ' לחוויה מתקדמת של הנאה, נוחות , איכות חיים וחיסכון בחשמל.\n' +
    'המערכת מאפשרת הפיכת כל בית חדש או קיים לבית חכם וחסכוני בחשמל ע"י החלפה פשוטה' +
    ' של המתגים הקיימים במתגי igh חכמים.\n' +
    ' סדרת מוצרי igh הינם ייחודיים בעלי טכנולוגיה מתקדמת המוגנת בפטנט ובעלי תקינה ישראלית, אירופאית ואמריקאית .\n' +
    ' בסדרה ניתן דגש רב לאיכות החומרים ולעיצוב המוצרים למתן חזקת חדשני ויוקרתי .\n' +
    'מתג חכם יודע לקבל החלטות באופן עצמאי בהתאם לסגנון חייו של המשתמש ובכך' +
    ' להדליק ולכבות עבורכם את התאורה, לפתוח ולסגור את התריסים, להדליק את המזגן לטמפרטורה' +
    ' הרצויה ועוד.\n' +
    ' \n .המערכת פועלת באמצעות שילוב בין חיישנים חכמים המזהים טמפרטורה מתגים ויחידות נוספות ההופכות את ביתכם לחכם, אוטומטי ומותאם לצרכיכם.\n' +
    'בנוסף, המערכת מאפשרת ניהול נכון של אנרגיה וחיסכון של כ 30% בהוצאות החשמל.\n';

export const about3 = 'בעשור האחרון פותחו טכנולוגיות אלחוטיות שמטרתן להחליף את' +
    ' הטכנולוגיות הקוויות ובכך לאפשר ליותר בתים להתקין בקלות ובמחירים זולים יותר מערכות בית חכם.\n' +
    'גם המערכות הקוויות וגם האלחוטיות משתמשות בבקר מרכזי. על מנת לאפשר שליטה בצרכני הבית השונים, ההתחברות בין יחידות הקצה (המפסקים החכמים)' +
    ' לבין הבקר המרכזי מתבצעת דרך רשת כזו או אחרת, הבקר המרכזי מקבל את הפקודה מיחידת הקצה ומבצע אותה.\n' +
    'המערכות האלחוטיות הנפוצות היום מבוססות על טכנולוגיות WiFi או ZigBee. במערכות אלו, עקב ריבוי הודעות ומרחקים גדולים בין יחידות הקצה לבקר וכן חשיפה להפרעות ועומס רב מתגלים לעיתים מצבים' +
    ' של חוסר אמינות והאטה בפקודות המתקבלות הן על ידי היחידה המרכזית והן על ידי יחידות הקצה.\n' +
    'עקב חסרונות אלו במערכות האלחוטיות נוצר חסם שלא מאפשר להביא את הטכנולוגיה לכלל הבתים בעולם.\n' +
    'טכנולוגית igh מבטלת לחלוטין את הצורך ביחידת בקרה מרכזית וכן ברשת האלחוטית. הטכנולוגיה מאפשרת לכל יחידת קצה להיות חכמה, לקבל מידע ' +
    'מחישני הסביבה של igh ויחידות קצה נוספות, לקבל החלטות פנימיות ולהריץ פקודות בהתאם להגדרות המשתמש.\n' +
    'הפתרון הייחודי של igh מאפשר שליטה ובקרה אוטומטית לחלוטין, התקנה פשוטה ללא תשתיות, אמינות של מערכת קווית ומחיר אטרקטיבי.';

export const aboutNewSec = 'המערכת פועלת באמצעות שילוב בין מפסקים חכמים, חיישנים, מגשרים ולוח מקשים.\n' +
    'הכל מתחיל בחיישן החכם -' +
    ' החיישן החכם יודע לזהות תנועה, למדוד את כמות האור והטמפרטורה בחדר ולשלוח את כל המידע הרלוונטי למפסק החכם.\n' +
    'המפסק החכם מקבל את המידע מהחיישן ומקבל החלטות באופן עצמאי ואוטומטי בהתאם לאורך חייכם כגון שינוי התאורה בחדר, מצב התריסים וכמובן טמפרטורת המזגן ליצירת נוחות מקסימלית.\n' +
    ' כמובן שניתן לשלוט על הבית מכל מקום בעולם וזה נעשה באמצעות המגשרים שלנו.' +
    '\n המגשרים מתקשרים עם הבית ובכך מאפשרים שליטה מרחוק על מוצרי החשמל ויחידות מערכת הבית החכם באמצעות האפליקציה.\n' +
    'בסדרה ניתן דגש רב לאיכות החומרים ועיצוב המוצרים למתן מראה חזק חודשני ויוקרתי.\n' ;

export const aboutNewFirst = 'חברת Green Point נוסדה בשנת 2010 כחברה המפתחת מערכת בית חכם המתאימה לכל אחד.\n' +
    'שמה הגיעה מאופן שמירתה על הסביבה דרך ניהול נכון של אנרגיה וחיסכון משמעותי בצריכת החשמל.\n' +
    'חזון החברה היה לייצר מערכת שתהיה אוטונומית לחלוטין ללא תלות בבקר מרכזי ורשת Wifi. \n ' +
    'הפתרון הייחודי של Green Point מאפשר שליטה ובקרה אוטומטית לחלוטין, התקנה פשוטה ללא תשתיות, אמינות של המערכת הקווית ומחיר אטרקטיבי.';

export const aboutNew=[
    aboutNewFirst,aboutNewSec, aboutThird,about3
];
export const aboutSupport = 'תמיכה מרוחקת ללא צורך בהגעה לבית הלקוח.';

export const aboutCustomers = 'הטכנולוגיה מתאימה לכולם!!!!\n' +
    'לווילת היוקרה ולדירה הקטנה, לבנין המשרדים, לבתי החולים, לחדרי הכושר, למלונות ולחדרי האירוח';

export const aboutFeatures = [
    'יכולות מדידה ייחודיות - כל מפסק igh מגיע עם מד צריכה ומודד את הזרם שלו בצורה עצמאית',
    'שליחת התראות בזמן אמת - הפעלת תרחישים ושליחת התראות בהתאם לאירועים בבית',
    'מערכת מבוזרת, מפסקים אוטונומיים עצמאיים, ללא נקודת כשל מרכזית ,אמינות ללא פשרות.',
    'מפסקים חכמים שלומדים את התנהגותך ומשנים קבלת החלטות בהתאם.',
    'בכל מפסק 3 מצבי עבודה לשליטה על ידי המשתמש - ידני, אוטומטי וחסכוני.',
    'אין צורך בתכנון מוקדם למיקום המפסקים החכמים בבית.',
    'אין מגבלת טווח RF, ניתן להתקנה בכל גודל של אתר.',
    'ניהול ומדידת תצרוכת חשמל על ידי כל מפסק חכם.',
    'חיישן נוכחות, טמפרטורה ואור הפועל באופן סולרי ללא צורך בהתקנה חשמלית או החלפת סוללות.',
    'חיסכון של כ- 30% בתצרוכת החשמל בבתים על ידי ביטול החשמל המבוזבז ללא פגיעה באיכות החיים.',
    'שליטה בעוצמת תאורה לקבלת אווירה מתאימה, כולל 3 נקודות מגע.',
    'שליטה בתריסים כולל 3 נקודות מגע פתח, סגור וסבסוב שלבים להגעה ממצב של תריס סגור למצב שנכנס אור.',
    'ניתן הפעיל את הבית מהנייד שלך גם ללא אינטרנט.',
    'שליטה, הוספה ושינוי הגדרות ישירות מהנייד.',
    'קבלת עדכונים לאפליקציות ללא תשלום.',
    'ניתן להגדיר מצבים ייחודיים, הפעלות שונות והודעות מייל. המשתמש יכול להגדיר בעצמו "אירועים ותגובות" עם מערכת ניהול האירועים באפליקציה.',
    'ניתן להפעיל את הבית ישירות ולהריץ תרחישים מלוח המקשים.',
    'להבדיל ממערכות אלחוטיות שמשדרות באופן קבוע, ב igh השידורים מאוד קצרים (כ 10 אלפיות השניה) ובעוצמה מאוד נמוכה.',
    'תמיכה במערכות צד ג כמו מזגני VRF, ספוטיפיי, אפל הום קיט, סירי, גוגל הום אלכסה ועוד.',
    'מערכת הביטחון והבטיחות מאפשרת בעזרת החיישנים לקבל התראה לנייד שלכם' +
    ' באירועים חריגים שהוגדרו על ידכם כגון כניסה לא מורשת לביתכם, התינוק לא זז במיטה בלילה, טמפרטורה חריגה באחד החדרים, ועוד.',
    'מנהל מיקום בזמן אמת - הרצת תרחישים בהתאם למקום הטלפון באמצעות GPS'
];

export const aboutInstall = [
    'התקנה פשוטה שיכולה להתבצע על ידי כל חשמלאי.',
    'אין צורך בתשתיות יעודיות ,מתאים לבתים ישנים וחדשים כאחד.',
    'אין צורך בהחלפת קופסאות החשמל בקיר.',
    'החיישן הסולרי מוצמד לקיר באמצעות מדבקה על תושבת ייעודית ללא צורך בחיווט.'
];