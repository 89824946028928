export const hotelPreText = 'מערכת מלונאית בטכנולוגית igh פותחה ועוצבה בקפדנות עבור בתי מלון ומוסדות אירוח תוך מתן דגש על צורכיהם המשתנים של אורחי המלון ומפעיליו';
export const hotelItems = [
    {
        title: 'המערכת המבוזרת היחידה בעולם המאפשרת התקנה פשוטה ובאמינות שרק טכנולוגית igh יכולה לתת.',
        text: '',
        img: 'hotel7.png'
    },{
        title: 'חדרים חכמים',
        text:
            'כבר עם פתיחת הדלת החדר יקבל את פני האורח באמצעות תסריט קבלת פנים, יעמעם את התאורות לרמה הרצויה ויפעיל את מיזוג האוויר.\n' +
            'בעת עזיבת החדר יכבו בהדרגה כל צרכני החשמל שנשארו דולקים באמצעות תסריט עזיבת החדר ללא צורך בתחנת עגינה למפתח החדר.\n' +
            'בנוסף,יספקו לוחות ההפעלה החכמים שליטה קלה ונוחה בחדר. כמו כן, מערכת בקרת חדרים חכמה תציג בזמן אמת מידע נחוץ כגון "דרוש ניקיון לחדר", "נא לא להפריע", ' +
            '"דלת החדר נשארה פתוחה", מידע על טמפרטורת החדר ועוד.'
    },{
        title: 'יתרונות',
        text: 'התקנה פשוטה ומהירה על בסיס תשתית המחשמל הקיימת, מתבצעת על ידי צוות המלון לאחר ליווי והכשרה בחינם מטעם חברתנו.\n' +
            'אוטומציה מלאה - קבלת החלטות משתנה בהתאם לבקשות המלון.\n' +
            'חדרים אוטונומיים לחלוטין ללא כל תלות ברשת תקשורת היוצרים אמינות ללא פשרות.\n' +
            'המערכת ניתנת להתקנה בכל מבנה ללא מגבלות טווחים.\n' +
            'שליטה ובקרה מרכזית.'
    },{
        title: 'שטחים ציבוריים',
        text: 'שליטה בכל סוגי התאורה כולל בקרת DMX למעגלי לדים צבעוניים ועוד.\n' +
            'התממשקות למערכות צד ג\' כגון אודיו/וידאו בחדרי ישיבות, מערכות מיזוג VRF ועוד.\n' +
            'שליטה על המערכת באמצעות אפליקציה ללא תשלום כולל יצירת תרחישים ותזמונים.\n' +
            'התראות בזמן אמת על אירועים שהתרחשו והפעלת תסריטים אוטומטית.\n' +
            'סטטיסטיקת צריכת חשמל מתקדמת עבור כל מעגל חשמלי.'
    }
];
