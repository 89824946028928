export const questions = [
    {
        question: 'באיזה גרסאות אנדרואיד ו iOS האפליקצייה נתמכת?',
        answer: 'אנדרואיד 4.2 ו-iOS 8 ומעלה'
    }, {
        question: 'אם האינטרנט בבית לא עובד - האם המערכת תעבוד?',
        answer: 'המערכת עובדת על פרוטוקול תקשורת RF תקשורת רדיו בטוחה שאיננה תלויה ברשת האינטרנט,' +
            'כך שהמערכת תמשיך לעבוד ללא קשר לרשת האינטרנט.\n' +
            'ברור שכדי להפעיל את המערכת מחוץ לבית באמצעות האפליקציה- יש צורך שהאינטרנט בבית יהיה תקין.'
    }, {
        question: 'אני רוצה להתקין בית חכם רק בסלון ובחדרי הילדים. האם אפשרי לעשות רק חלק מהבית?',
        answer: 'מערכת הבית החכם היא מודולרית. אתם יכולים לבחור אילו חלקים מהבית תרצו להפוך לחכמים.' +
            ' בכל נקודת זמן תוכלו להגדיל את הבית החכם ולהוסיף עוד חלקים בעלות ביקור טכנאי של מאות שקלים בודדים.'
    }, {
        question: 'האם יש בקר מרכזי? מה קורה אם הוא נופל?',
        answer: 'לא. כל מתג מכיל מחשב עצמאי הפועל באופן אוטומטי ללא תלות בבקר מרכזי וללא תלות ברשת אינטרנט.'
    }, {
        question: 'אילו מכשירים חשמליים אני יכול לחבר למערכת?',
        answer: 'ניתן להחליף כל מתג בבית למתג חכם. ' +
            'מתג תאורה חכם יאפשר לכם להפוך את כל גופי התאורה בבית לתאורה חכמה: שנדלירים, לדים, נברשות, הלוגן, אדיסון. ' +
            'אם יש לכם גופי תאורה הניתנים לדימור- ניתן יהיה לדמר אותם דרך המערכת.\n' +
            'מתג תריס חכם- מאפשר לכם לשלוט על התריסים מרחוק ואף להגדיר מצב של אוטומציה בהם התריסים יפתחו או יסגרו לפי כמות האור שאתם רוצים שתהיה בחדר.\n' +
            'מתג כח מאפשר לחבר את כל ״צרכני החשמל הכבדים״: מזגנים, דודים חשמליים ואפילו את מכונות הכביסה והמייבשים. ' +
            'מתג שלט רחוק/שלט רחוק חכם- מאפשר לכם להקליט את השלטים של מכשירי ' +
            'החשמל לתוך מערכת הבית החכם כך שתוכלו לשלוט בהם מרחוק ואף לערוך תסריטים הכוללים את הפעלה של המכשירים.\n' +
            'הטלויזיה, מערכת הסאונד, והמזגנים- יהפכו להיות חלק ממערכת הבית החכם שלכם!'
    }, {
        question: 'מה קורה אם אני ארצה להרחיב את הבית החכם שלי? אפשרי?',
        answer: 'בכל נקודת זמן תוכלו להגדיל את הבית החכם ולהוסיף עוד חלקים בעלות ביקור טכנאי של מאות שקלים בודדים.'
    }, {
        question: 'האם ניתן להתקין בדירה שכורה? מה קורה כשאני עובר דירה?',
        answer: 'את הבית החכם ניתן להתקין גם בדירות ישנות ואפילו בדירות שכורות. ניתן להעביר את המערכת איתכם בעת המעבר באמצעות פירוק והתקנה פשוטים על ידי צוות המתקינים שלנו.'
    }, {
        question: 'יש לי בית ישן בן 40 שנה. אין לי מושג מה מצב החשמל... האם אני יכולה להתקין גם?',
        answer: 'כל בית יכול להפוך לבית חכם.\n' +
            'גם אם ברשותך מפסקים ישנים, ואפילו אם אין קו אפס - החשמלאי שלנו יגיע לביתך, ויתקין את מערכת הבית החכם.' +
            '(בתנאי שחיבורי החשמל בביתך לפי החוק והתקן).'
    }, {
        question: 'יש לי דוד מים חשמלי- האם אני יכול לחבר אותו למערכת?',
        answer: 'בוודאי! באמצעות חיבור הדוד ל״מתג כח״.' +
            'חיבור הדוד למערכת הבית החכם תאפשר לכם לשלוט על הדוד באמצעות האפליקציה,' +
            ' להפעיל ולכבות מרחוק, לתזמן את הדוד לפי שעות וימים ואפילו תוכלו לנטר ולעקוב אחר צריכת החשמל של הדוד החשמלי.'
    }, {
        question: 'יש לי בבית מזגן מיני מרכזי, עם שלטים בסלון ובחדר השינה. האם ניתן לחברם למערכת הבית החכם?',
        answer: 'כל מזגן יכול להתחבר למערכת הבית החכם.\n' +
            'מזגן מיני מרכזי עם שלטים- ניתן להפעיל באמצעות המערכת, לתכנת כל שלט בנפרד, וליהנות ממגוון האפשרויות של הבית החכם: שליטה מרחוק, הפעלה חלקית, בניית תסריטים ואפילו הפעלות אוטומטיות ומתוזמנות.' +
            'בנוסף ניתן לעקוב אחר צריכת החשמל של המזגן בכל רגע נתון.\n' +
            'מזגנים עיליים ניתנים אף הם לחיבור למערכת, עם אפשרות לתיכנות שלט ומעקב אחר צריכת החשמל.'
    }, {
        question: 'היכן מיוצרים המוצרים ואיזה תקינה עברו?',
        answer: 'כל מוצרי igh פותחו ומיוצרים בישראל ע"י חברת גרין פוינט.\n' +
            'למוצרי igh תקינה מלאה בישראל אירופה וארה"ב, בנוסף לאישור חברת החשמל בישראל.'
    }
];
